
import React, { forwardRef, useImperativeHandle, useState } from "react";
import "../../scss/popup.scss";
import { isValidEmail } from '../../utils/utils';
import CustomPopup from './custom-popup';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarListingPopup = forwardRef((props, ref) => {
    const [show, setShow] = React.useState(false);
    const [showForm, setShowForm] = useState(true);
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const popupCloseHandler = (e) => {
        setShow(false);
    };
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        close() {
            setShow(false);
        },

        open(email = '') {
            if (email) {
                setEmail(email);
            }
            setShow(true);
        }
    }));

    return (
        <>
            <CustomPopup
                onClose={popupCloseHandler}
                show={show}
                title="">
                <div class="_form-thank-you">
                    <h3 className='text-center'>Please Choose Your Webinars</h3>
                    <p className='text-center'>
                        <a className="sec-clr" href="https://webinarkit.com/webinar/registration/675e98b8de9c1309098a8e07" aria-label="3-Steps Strategy Guide to Protect Your Software Innovations" target="_blank">
                            <strong>3-Steps Strategy Guide to Protect Your Software Innovations</strong>
                        </a>
                    </p>
                    <p className='text-center sec-clr'>
                        <a className="sec-clr" href="https://webinarkit.com/series/registration/674ba663d73b6929423d6ec0" aria-label="The Future of Patent Drafting" target="_blank">
                            <strong>The Future of Patent Drafting</strong>
                        </a>
                    </p>
                    <p className='text-center sec-clr'>
                        <a className="sec-clr" href="https://webinarkit.com/webinar/registration/67484876404ac8b0ae396fb3" aria-label="AI Meets IP: Welcome to The future of Patent Drafting" target="_blank">
                            <strong>AI Meets IP: Welcome to The future of Patent Drafting</strong>
                        </a>
                    </p>
                </div>
            </CustomPopup>
        </>
    )
});

export default WebinarListingPopup;