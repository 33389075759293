/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, canonical, seo }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = seo?.metaDesc || description || site.siteMetadata.description;
  const keywords = seo?.focuskw || seo?.metaKeywords || seo?.title || 'Patents, Trademarks, Copyrights, Trade Secrets';
  const seoTitle = seo?.title || title;
  const ogImage = seo?.opengraphImage?.sourceUrl;
  const defaultTitle = site.siteMetadata?.title;
  const siteUrl = site.siteMetadata?.siteUrl;
  const siteName = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[
        {
          rel: `canonical`,
          href: `${siteUrl}${canonical}`,
        }
      ]}
    >
      <link rel="dns-prefetch" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://www.google.com" />
      <link rel="preconnect" href="https://www.gstatic.com" crossorigin></link>
      <link rel="preload" as="image" href="/assets/img/avatar/man-1.webp"></link>
      <link rel="preload"
        as="style"
        onload="this.rel='stylesheet'"
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800%7CPoppins:400,500,700,800,900%7CRoboto:100,300,400,400i,500,700" />
      <link rel="preload" href="https://fonts.googleapis.com/css?family=Great+Vibes"
        as="style"
        onload="this.rel='stylesheet'" />
      <link rel="preload"
        as="style"
        onload="this.rel='stylesheet'" href="/assets/fonts/font-awesome/css/font-awesome.min.css"></link>
      <link rel="preload"
        as="style"
        onload="this.rel='stylesheet'" href="/assets/fonts/flaticon/font/flaticon.css"></link>
      <link rel="preload"
        as="style"
        onload="this.rel='stylesheet'" href='https://fonts.googleapis.com/css?family=Glegoo%3Ainherit%2C500%2C400&#038;subset=latin%2Clatin-ext&#038;ver=6.1.4' />
      <link rel="preload"
        as="style"
        onload="this.rel='stylesheet'" href='https://fonts.googleapis.com/css?family=Glegoo%3A700%2Cregular&#038;subset=devanagari&#038;ver=6.1.4' />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      <meta name="news_keywords" content={keywords} />
      <meta name="author" content="@Inventiv" ></meta>
      <meta name="copyright" content="@Inventiv" />
      <meta http-equiv="expires" content="0" />
      <meta name="resource-type" content="document" />
      <meta name="distribution" content="global" />
      <meta name="robots" content="index, follow" />
      <meta name="Googlebot-News" content="index, follow, archive, snippet" />
      <meta name="googlebot" content="index, follow, archive, snippet" />
      <meta name="revisit-after" content="1 days" />
      <meta name="rating" content="general" />
      <meta name="language" content="en" />
      <meta name="geo.region" content="US-CA" />
      <meta name="geo.placename" content="San Jose" />
      <meta name="geo.position" content="37.2965315,-122.0975993" />
      <meta name="ICBM" content="37.2965315,-122.0975993" />

      {/* <!-- META FOR FACEBOOK --> */}
      <meta property="facebook-domain-verification" content="jxqr2jjx78dyceyyru5iyxh1buwdcp" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:image" content={seo?.opengraphImage?.sourceUrl} />
      <meta property="article:publisher" content="@Inventiv" />
      <meta property="og:url" content={siteUrl + canonical} />
      <meta property="og:rich_attachment" content="true" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="354" />
      <meta property="article:published_time" content={seo?.opengraphPublishedTime} />
      <meta property="article:modified_time" content={seo?.opengraphModifiedTime} />
      {/* <!-- END META FOR FACEBOOK --> */}
      {/* <!-- Twitter Card --> */}
      <meta name="twitter:card" value="summary" />
      <meta name="twitter:url" content={siteUrl + canonical} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={seo?.opengraphImage?.sourceUrl} />
      <meta name="twitter:site" content="@Inventiv" />
      <meta name="twitter:creator" content="@Inventiv" />
      {/* <!-- End Twitter Card --> */}
      <meta name="DC.title" content={seoTitle} />
      <meta name="DC.description" lang="en" content={metaDescription} />
      <meta name="DC.keywords" content={keywords} />
      <meta name="DC.creator" content="@Inventiv" />
      <meta name="DC.publisher" content="@Inventiv" />
      <meta name="DC.format" scheme="IMT" content="text/html" />
      <meta name="DC.language" scheme="RFC1766" content="en" />
      {/*  Google Tag Manager */}
      {/* <script async>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MX5HKMM');
        `}
      </script> */}
      {/* <script async>
        {`
          (function(ng,a,g,e,l,i,ve){l = a.createElement(g),l.async=1,l.src=ng+e;var c=a.getElementsByTagName(g)[0];c.parentNode.insertBefore(l,c);var i=a.createElement('div');var ve='style';i.id='nGageLH',i[ve].position='fixed',i[ve].right='0px',i[ve].bottom='0px',i[ve].zIndex='5000',a.body&&a.body.appendChild(i);}('https://messenger.ngageics.com/ilnksrvr.aspx?websiteid=',document,'script','183-30-74-135-85-102-64-110'));
        `}
      </script> */}
      {/* Webinar */}
      {/* <script async>
        {`
          (function (w, d, s, o, f, js, fjs) { w['eWidget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; if (d.getElementById(o)) return; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', '_ew', 'https://app.ewebinar.com/widget.js')); _ew('init', { "root": "w1709621037352", "isReview": false, "mode": "public", "openInPopup": false, "for": "Registration", "type": "Button", "source": "Schedule_Webinar", "url": "https://patent.ewebinar.com/webinar/navigating-patent-process-14153", "shortUrl": "https://patent.ewebinar.com/webinar/14153", "sessions": [], "formType": "LatestForm", "ewebinar": { "title": "Navigating%20Patent%20Process", "borderRadius": 50, "primaryColor": "#FF746A", "readableColor": "#ffffff", "actionColor": "#39a1b2", "readableActionColor": "#ffffff", "readableOnWhiteColor": "#FF746A", "language": "en" }, "showGdprBanner": false, "gdprBannerMode": "Off", "gdprBannerText": "", "hideBranding": false, "teamId": "4147", "carouselId": "", "isWebinarRegPage": false, "isMobile": false, "button": { "btnText": "Schedule Webinar", "showButtonTimer": false, "buttonPrimaryColor": "#fbb848ff", "buttonReadableColor": "#ffffff", "align": "Right", "isFullWidth": false, "fontSize": "17" } }); window.ewInit && window.ewInit();
        `}
      </script> */}
      {/* <!-- Hotjar Tracking Code for https://www.inventiv.org --> */}
      {/* <script>
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2750017,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script> */}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
